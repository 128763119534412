<template>
  <div>
    <v-progress-circular v-if="loading"
                         :size="20"
                         width="1"
                         color="primary"
                         indeterminate
    ></v-progress-circular>
    <div v-else>
      <div v-if="$store.getters.checkPermission('can_add_sg')">
        <v-dialog max-width="500" v-model="createStudentGroupModalShow">
          <CreateStudentGroupModal @create="load" :department_id="department_id"
                                   @close="createStudentGroupModalShow = false"/>
        </v-dialog>
        <v-btn @click="createStudentGroupModalShow = true" small rounded color="green" outlined>Добавить студ. группу
        </v-btn>
      </div>
      <div>
        <v-list v-if="student_groups.length" dense>
          <v-list-item selectable v-for="g in student_groups" :key="g.id">
            <v-list-item-content>
              <v-list-item-title>
                {{ g.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ g.count_students }} {{ plural(g.count_students, 'студент', 'студента', 'студентов') }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-dialog max-width="700">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon color="grey lighten-1">mdi-account</v-icon>
                  </v-btn>

                </template>
                <StudentGroupControlModal :name="g.name" :id="g.id"/>
              </v-dialog>

            </v-list-item-action>
            <v-list-item-action v-if="$store.getters.checkPermission('can_add_sg')">
              <v-btn @click="$store.dispatch('deleteStudentGroup', {id: g.id}).then(() => load());" :disabled="!!g.count_students" color="red" icon>
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else>
          Нет студенческих групп
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import student_groups from "@/api/student_groups.js";
import CreateStudentGroupModal from "@/components/Timetables/DisplinesCreator/StudentGroupCreateModal";
import StudentGroupControlModal from "@/components/Admin/Groups/StudentGroupControlModal.vue";
import plural from "@/plural.js";

export default {
  name: "DepartmentComponent",
  components: {CreateStudentGroupModal, StudentGroupControlModal},
  props: ['department_id'],
  data() {
    return {
      loading: true,
      createStudentGroupModalShow: false,
      student_groups: []
    }
  },
  methods: {
    load() {
      this.loading = true;
      student_groups.getByDepartment(this.department_id).then((r) => {
        this.student_groups = r.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.load();
    this.plural = plural;
  }
}
</script>

<style scoped>

</style>
