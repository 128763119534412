<template>
  <div>
    <div class="d-md-flex justify-space-between my-2">
      <h4>Подразделения</h4>
    </div>
    <v-expansion-panels accordion>
      <v-expansion-panel
          v-for="(item,i) in departments"
          :key="i"
      >
        <v-expansion-panel-header>
          {{ item.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DepartmentComponent :department_id="item.id"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DepartmentComponent from "@/components/Admin/Groups/DepartmentComponent.vue";
import _ from 'lodash';

export default {
  name: "AdminGroupsComponent",
  components: {DepartmentComponent},
  computed: {
    departments() {
      return this.$store.state.dictionaries.departments;
    }
  },
  methods: {
    getGroupsByDepartment(id) {
      return _.find(this.$store.state.dictionaries.studentGroups, {department_id: id})
    }
  }
}
</script>

<style scoped>

</style>
