<template>
  <v-card outlined>
    <v-card-title>{{ name }}</v-card-title>
    <v-card-text>
      <v-text-field autofocus v-model="search" placeholder="Поиск"/>
      <v-progress-circular v-if="loading"
                           :size="20"
                           width="1"
                           color="primary"
                           indeterminate
      ></v-progress-circular>
      <div v-else>
        <div class="mt-2 mr-2 d-inline-block" v-for="user in filteredUsers" :key="user.id">
          <UserLinkComponent v-if="user.id" :user="user"/>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import student_groups from "@/api/student_groups.js";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import _ from 'lodash';

export default {
  name: "StudentGroupControlModal",
  components: {UserLinkComponent},
  computed: {
    filteredUsers() {
      return this.search ? _.filter(this.users, (u) => u.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.users
    }
  },
  props: ['name', 'id'],
  data() {
    return {
      loading: true,
      search: '',
      users: []
    }
  },
  mounted() {
    student_groups.users({id: this.id}).then((r) => {
      this.users = r.data;
    }).finally(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
